import React from 'react';
import PropTypes from 'prop-types';

const Tag = ({ text }) => {
  return (
    <div className="text-xs sm:text-sm py-0.5 px-2 bg-htgGreen-light font-light rounded-xl">
      {text}
    </div>
  );
};

Tag.propTypes = {
  text: PropTypes.string
};

Tag.defaultProps = {
  text: ''
};

export default Tag;
